export default {
  buildTime: '2022-03-14T17:04:56Z',
  commit: '3cecc9367213690a4d89773281fbed05a1a05e57',
  npmInfo:
{
  mashlib: '1.8.0',
  npm: '6.14.13',
  ares: '1.17.1',
  brotli: '1.0.9',
  cldr: '39.0',
  icu: '69.1',
  llhttp: '2.1.3',
  modules: '83',
  napi: '8',
  nghttp2: '1.42.0',
  node: '14.17.3',
  openssl: '1.1.1k',
  tz: '2021a',
  unicode: '13.0',
  uv: '1.41.0',
  v8: '8.4.371.23-node.67',
  zlib: '1.2.11'
}
}
